@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    /* --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0; */

    --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
  to bottom,
  transparent,
  rgb(var(--background-end-rgb))
  )
  rgb(var(--background-start-rgb));
}

.t-shadow {
  text-shadow: rgba(171, 227, 236, 0.8) 1px 1px 2px;
}
.t-shadow-dark {
  text-shadow: rgb(19 80 102 / 0.8) 1px 1px 2px;
}
@layer components {
  .jumboGradient {
    background: -moz-linear-gradient(45deg, rgba(221, 139, 163, 0.411), rgba(171, 227, 236, 0.4) 100%);
    background: -o-linear-gradient(45deg, rgba(221, 139, 163, 0.411), rgba(171, 227, 236, 0.4) 100%);
    background: linear-gradient(45deg, rgba(221, 139, 163, 0.411), rgba(171, 227, 236, 0.4) 100%);
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .progress-bar {
    @apply z-40 bg-primary-500 border border-primary-500 top-16 fixed left-0 right-0 h-1;
    transform-origin: 0%;
  }

  /*
  Formateo de Markdown
   **/

.md-content {
  @apply justify-center text-justify tracking-wide h-full py-10 leading-loose;

  p {
    @apply mb-4 text-lg py-4;

  }

  p>a {
    @apply font-medium text-primary-400;
  }

  p>a:hover {
    @apply text-secondary-600 underline decoration-double;
  }

  blockquote {
    @apply relative pt-[1em] border-t-2 mt-10 border-primary-500;

    p {
      @apply font-medium text-xl py-4;
    }
  }

}

.md-blog {
  @apply justify-center text-justify h-full py-12 px-4 md:py-10;

  p {
    @apply mb-4 text-xl leading-[1.8em] tracking-wider font-light;

  }

  h2 {
    @apply text-left text-3xl font-bold text-primary-900 mb-8;
  }

  h3 {
    @apply text-left text-2xl font-bold text-primary-800 mb-8;
  }


  p>a {
    @apply font-medium text-primary-400 px-4;
  }

  p>a:hover {
    @apply bg-primary-600 py-1 rounded text-white;
  }

  blockquote {
    @apply relative pt-[1em] border-t-2 mt-10 border-primary-500;

    p {
      @apply font-medium text-xl py-4;
    }
  }

  ul {
    @apply list-none my-8;
  }


  ul > li {
      @apply rounded-md px-4 py-2 transition-all duration-75 tracking-wide text-left bg-white hover:bg-primary-100 text-xl;

      margin: revert;
      position: relative;
  }

  ul > li:before {
    @apply bg-white;
    content: '';
    position: absolute;
    margin: auto;

    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;

    transform: skew(2deg, 2deg);
  }

  ul > li:nth-child(1):before {
    @apply: bg-primary-500;
    background: -webkit-linear-gradient(to right, #19daf7, #fba6d4);
    background: linear-gradient(to right, #19daf7, #fba6d4);
  }

  ul > li:last-child:before {
    @apply: bg-secondary-500;
    background: -webkit-linear-gradient(to right, #19daf7, #fba6d4);
    background: linear-gradient(to right, #19daf7, #fba6d4);
  }

  ul > li:after {
    content: '';
    position: absolute;
    width: 40%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.02);
  }

  ol,
  ol.footnotes{
    @apply list-none my-2;
  }

  ol > li {
    @apply rounded-md md:px-10 py-2 transition-all duration-75 tracking-tighter text-justify;
    }

  ol > li:before {
    @apply bg-primary-500;
    content: '';
    width: 10px;
    height: 10px;

    position: absolute;
    border-radius: 50%;
    left: -15px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .footnotes {
    @apply rounded-md py-0 transition-all duration-75 tracking-tighter text-justify w-full;
  }

  section.footnotes > ol > li {
    @apply rounded-md md:px-10 py-0 transition-all duration-75 tracking-tighter text-justify ;
  }
  section.footnotes > ol >li > p {
    @apply py-0 text-xs leading-6;
  }

}

/** md-blog **/

.whats-btn {
  @apply bg-secondary-100 z-30 fixed items-center w-16 h-[66px] p-1 decoration-0;
  position: fixed;
  bottom: 2em;
  right: 25px;
  text-decoration: none;
  display: none;
  border-radius: 50%;

  & span {
    @apply w-[56px] h-[56px] block;
    border-radius: 50%;
    background-image: url("/images/whatsapp_45x39.png");
    background-size: 100%;
  }
}

.whats-btn:hover {
  @apply bg-primary-500;
}

.whats-btn-show {
  @apply block;
}

.bg-whatsapp {
  background: url('/images/background-whatsapp.jpg') center center / cover no-repeat;
}
}
/**
-- Fin de Components
 **/

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
  .debug-red {
    border: solid 1px red;
  }
  .debug-yellow {
    border: solid 1px yellow;
  }
  .debug-blue {
    border: solid 1px blue;
  }
  .debug-green {
    border: solid 1px green;
  }
}
